import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import moment from 'moment';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'dialog-bulk-web-refund-ticket',
  templateUrl: './dialog-bulk-web-refund-ticket.component.html',
  styleUrls: ['./dialog-bulk-web-refund-ticket.component.scss']
})
export class DialogBulkWebRefundTicketComponent implements OnInit {
  languageKey: string;
  totalReservations: number;
  lastPageNumber: number;
  response: any;
  intervalId: any;
  commonObject: Common;
  isCheckedFilterOutUsedTickets: boolean;
  Helper = Helper;
  dataSearch: any;
  startDate: any;

  /**
   * kiểm tra trạng thái đang refund hay không
   */

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketManagerService: TicketManagerService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    if (data) {
      this.languageKey = data.languageKey;
      this.totalReservations = data.totalReservations;
      this.lastPageNumber = data.lastPageNumber;
      this.dataSearch = data.dataSearch;
    }
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.resetResponse();
    this.ticketManagerService.loadRefundList('count').subscribe(
      data => {
        if (!data) {
          this.resetResponse();
          return;
        } else {
          this.response.filename = data.filename;
          this.response.status = data.status;
          this.response.total = data.total;
          this.response.refundList = data.refundList;
          this.response.done = data.done;
          this.response.failed = data.failed;
          this.response.startDate = Helper.convertTimeToTimeCMP(data.startTime, this.commonObject, 'YYYY年MM月DD日 HH:mm:ss');
          this.startDate = Helper.convertTimeToTimeCMP(data.startTime, this.commonObject, 'YYYY/MM/DD HH:mm:ss');
          //Set time
          if (this.response.status == 'done') {
            this.response.endDate = Helper.convertTimeToTimeCMP(data.endTime, this.commonObject, 'YYYY年MM月DD日 HH:mm:ss');
          } else if (this.response.status == 'work') {
            this.intervalId = setInterval(() => {
              this.loadRefundList('count');
            }, 5000);
          }
        }
      },
      error => {
        this.handleLoadRefundList(error.status);
      }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  /**
   * resetResponse
   */
  resetResponse(): void {
    this.response = {
      startDate: '',
      endDate: '',
      filename: undefined,
      status: undefined,
      total: undefined,
      refundList: undefined,
      done: undefined,
      failed: undefined
    };
    this.isCheckedFilterOutUsedTickets = true;
  }

  /**
   * loadRefundList
   */
  loadRefundList(output: string, isCancel?: boolean): void {
    if (!output) {
      return;
    }
    this.ticketManagerService.loadRefundList(output).subscribe(
      data => {
        if (!data) {
          this.resetResponse();
          return;
        } else {
          if (output == 'count') {
            this.response.filename = data.filename;
            this.response.status = data.status;
            this.response.total = data.total;
            this.response.refundList = data.refundList;
            this.response.done = data.done;
            this.response.failed = data.failed;
            this.response.startDate = Helper.convertTimeToTimeCMP(data.startTime, this.commonObject, 'YYYY年MM月DD日 HH:mm:ss');
            if (this.response.status == 'done') {
              this.response.endDate = Helper.convertTimeToTimeCMP(data.endTime, this.commonObject, 'YYYY年MM月DD日 HH:mm:ss');
              clearInterval(this.intervalId);
              if (isCancel) {
                this.handleCancelSuccess();
              }
            }
          } else if (output == 'raw') {
            this.download(data);
          }
        }
      },
      error => {
        this.resetResponse();
        clearInterval(this.intervalId);
        this.handleLoadRefundList(error.status);
      }
    );
  }

  /**
   * refund
   * @returns
   */
  refund() {
    if (this.validateRefund() || !this.dataSearch) {
      return;
    }
    const confirmText = this.isCheckedFilterOutUsedTickets
      ? this.translateService.instant('dialog-bulk-web-refund-ticket.confirm-refund-has-checked')
      : this.translateService.instant('dialog-bulk-web-refund-ticket.confirm-refund-hasnt-checked');
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: confirmText,
          button1: this.translateService.instant('dialog-bulk-web-refund-ticket.refund-execution'),
          button2: this.translateService.instant('dialog-bulk-web-refund-ticket.cancel')
        }
      },
      async result => {
        if (!result) {
          return;
        }

        let listOrderId = [];

        if (this.lastPageNumber) {
          // Xử lý theo từng batch 3 request
          for (let i = 1; i <= this.lastPageNumber; i += 3) {
            const promises = [];
            const remainingPages = this.lastPageNumber - i + 1; // Số trang còn lại
            const batchSize = Math.min(3, remainingPages); // Số trang cần lấy trong batch này

            // Tạo batch 3 promises
            for (let j = 0; j < batchSize; j++) {
              const payload = {
                reservationId: this.dataSearch.reservationId,
                representativeName: this.dataSearch.representativeName,
                phoneNumber: this.dataSearch.phoneNumber,
                email: this.dataSearch.email,
                userId: this.dataSearch.userId,
                reservationVerifyCode: this.dataSearch.reservationVerifyCode,
                reservationStatus: this.dataSearch.reservationStatus,
                orderUsageStatus: this.dataSearch.orderUsageStatus,
                appId: this.dataSearch.appId,
                orderId: this.dataSearch.orderId,
                ticketId: this.dataSearch.ticketId,
                operatorId: this.dataSearch.operatorId,
                reservationClassId: this.dataSearch.reservationClassId,
                tripId: this.dataSearch.tripId,
                startDate: this.dataSearch.startDate ?? '',
                endDate: this.dataSearch.endDate ?? '',
                pageNumber: i + j,
                pageLimit: this.dataSearch.pageLimit,
                sortBy: this.dataSearch.sortBy,
                originStopName: this.dataSearch.originStopName,
                destStopName: this.dataSearch.destStopName,
                defaultTime: this.dataSearch.defaultTime
              };

              promises.push(this.ticketManagerService.getReservations(payload).toPromise());
            }

            try {
              // Call 3 API cùng lúc
              const results = await Promise.all(promises);

              // Xử lý kết quả của 3 API
              results.forEach(data => {
                if (data) {
                  const reservations = data['reservations'];
                  reservations.forEach(e => {
                    if (e.orderId) {
                      listOrderId.push(e.orderId);
                    } else {
                      listOrderId.push(null);
                    }
                  });
                }
              });

              // Nếu là batch cuối cùng
              if (i + 2 >= this.lastPageNumber) {
                const refundPayload = {
                  type: 'new',
                  limitUsedOrder: this.isCheckedFilterOutUsedTickets ? true : false,
                  data: listOrderId
                };

                try {
                  const res1 = await this.ticketManagerService.createRefundList(refundPayload).toPromise();
                  if (res1 == null) {
                    this.intervalId = setInterval(() => {
                      this.loadRefundList('count');
                    }, 5000);
                  }
                } catch (error) {
                  this.handleCreateRefundList(error.status);
                }
              }
            } catch (error) {}
          }
        }
      }
    );
  }

  /**
   * validateRefund
   * @returns
   */
  validateRefund() {
    if (this.totalReservations > 5000) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-bulk-web-refund-ticket.error-max')
        }
      });
      return true;
    }
    return false;
  }

  /**
   * download
   * @param data
   */
  download(data: any) {
    let rows = [];
    if (data) {
      const convertData = (data: any) => {
        // Convert refundList
        const refundListObjects = data.refundList.map(id => ({
          id: id
        }));
        // Convert done array with formatted timestamp
        const doneObjects = data.done.map(item => ({
          id: item.id,
          timestamp: Helper.convertTimeToTimeCMP(item.timestamp, this.commonObject, 'YYYY/MM/DD HH:mm:ss')
        }));
        // Convert failed array with formatted timestamp
        const failedObjects = data.failed.map(item => ({
          id: item.id,
          timestamp: Helper.convertTimeToTimeCMP(item.timestamp, this.commonObject, 'YYYY/MM/DD HH:mm:ss'),
          message: item.message
        }));
        // Combine all arrays
        return [...refundListObjects, ...doneObjects, ...failedObjects];
      };
      const result = convertData(data).sort((a, b) => {
        // If a doesn't have timestamp, put it at the end
        if (!a.timestamp) return 1;
        // If b doesn't have timestamp, put it at the end
        if (!b.timestamp) return -1;
        // If both have timestamps, compare them normally
        return a.timestamp.localeCompare(b.timestamp);
      });
      if (!result.length) {
        rows = [
          ['払戻開始日時'],
          ['払戻終了日時'],
          ['完了', ''],
          ['失敗', ''],
          ['中止', ''],
          [],
          ['処理日時', 'オーダーID', 'ステータス', '失敗理由']
        ];
      } else {
        rows = [
          ['払戻開始日時', this.startDate],
          ['払戻終了日時'],
          ['完了', data.done.length],
          ['失敗', data.failed.length],
          ['中止', data.refundList.length],
          [],
          ['処理日時', 'オーダーID', 'ステータス', '失敗理由']
        ];
        let maxTimestamp = '';
        result.forEach(e => {
          if (e.timestamp && e.message) {
            // failed
            rows.push([e.timestamp, e.id, '失敗', e.message]);
            maxTimestamp = e.timestamp;
          } else if (e.timestamp) {
            // done
            rows.push([e.timestamp, e.id, '完了']);
            maxTimestamp = e.timestamp;
          } else {
            // stopped
            rows.push(['', e.id, '中止']);
          }
        });
        // Set endDate
        rows[1][1] = maxTimestamp;
        // Add BOM to support UTF-8 encoding
        const BOM = '\uFEFF';
        // Convert rows to CSV string
        const csvContent =
          BOM +
          rows
            .map(row => {
              return row
                .map(cell => {
                  const cellStr = String(cell);
                  if (cellStr.includes(',')) {
                    return `"${cellStr}"`;
                  }
                  return cell;
                })
                .join(',');
            })
            .join('\n');
        // Create blob from CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // Create download link
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        const currenDate = Helper.convertTimeToTimeCMP(new Date(), this.commonObject, 'YYYYMMDDHHmmss');
        let fileName = `一括Web払戻_${currenDate}.csv`;
        link.download = fileName;
        // Trigger download
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }
  }

  /**
   * cancel
   */
  cancel(): void {
    const MAX_POLLING_TIME = 60000;
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('dialog-bulk-web-refund-ticket.confirm-cancel'),
          button1: this.translateService.instant('dialog-bulk-web-refund-ticket.cancelled'),
          button2: this.translateService.instant('dialog-bulk-web-refund-ticket.cancel')
        }
      },
      result => {
        if (!result) {
          return;
        }
        const payload = {
          type: 'cancel',
          data: [this.response.filename]
        };
        this.ticketManagerService.createRefundList(payload).subscribe(
          data => {
            clearInterval(this.intervalId);
            const startTime = Date.now();
            //Chưa chắc có check được status của api hay không nên tạm thời không check điều kiện
            this.intervalId = setInterval(() => {
              const elapsedTime = Date.now() - startTime;
              // Nếu đã quá 1 phút, dừng interval và xử lý thất bại
              if (elapsedTime >= MAX_POLLING_TIME) {
                clearInterval(this.intervalId);
                this.handleCancelFailed();
                return;
              }
              this.loadRefundList('count', true);
            }, 3000);
          },
          error => {
            this.handleCreateRefundList(error.status);
          }
        );
      }
    );
  }

  /**
   * handleSuccess
   */
  private handleCancelSuccess(): void {
    clearInterval(this.intervalId);
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-bulk-web-refund-ticket.cancel-refund-success')
      }
    });
  }

  /**
   * handleCancelFailed
   */
  private handleCancelFailed(): void {
    clearInterval(this.intervalId);
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-bulk-web-refund-ticket.cancel-refund-failed')
      }
    });
  }

  /**
   * handleLoadRefundList
   * @param status
   * @returns
   */
  private handleLoadRefundList(status: any) {
    if (!status) {
      return;
    }
    switch (status) {
      case 403:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-auth')
          }
        });
        break;
      case 401:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-token')
          }
        });
        break;
      case 400:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-validation')
          }
        });
        break;
      case 404:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.confirm'),
            text: this.translateService.instant('dialog-error.error-refund-history')
          }
        });
        break;
      default:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-default')
          }
        });
        break;
    }
  }

  /**
   * handleCreateRefundList
   * @param status
   */
  private handleCreateRefundList(status: any) {
    switch (status) {
      case 403:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-auth')
          }
        });
        break;
      case 401:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-token')
          }
        });
        break;
      case 400:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-validation')
          }
        });
        break;
      case 404:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-cancel-refund')
          }
        });
        break;
      case 409:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.confirm'),
            text: this.translateService.instant('dialog-error.error-refund')
          }
        });
        break;
      default:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-error.error-default')
          }
        });
        break;
    }
  }
}
